import { formatFacetName, pushAnalyticsObjToDataLayer } from "@/utils/helper"
import { getAppliedFiltersForAnalytics } from "@/components/ProductList/v2/Filter/helper"

const getShowHideFilterOptionAnalyticsData = (
  collapse,
  facet,
  facetName,
  page,
  selectedTab = "products"
) => {
  let analyticsData = JSON.stringify({
    eventAction: `plp filter ${collapse[facet] ? "expand" : "collapse"}`,
    eventName: `plp filter ${collapse[facet] ? "expand" : "collapse"}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: collapse[facet] ? "expand" : "collapse",
    internalLinkPosition: "plp",
    internalLinkType: "plp:" + facetName,
    internalLinkZoneName: "plp:filters",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
  if (page === "search") {
    analyticsData = JSON.stringify({
      eventAction: `search results:${selectedTab}:filters ${
        collapse[facet] ? "expand" : "collapse"
      }`,
      eventName: `search results:${selectedTab}:filters ${
        collapse[facet] ? "expand" : "collapse"
      }`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: collapse[facet] ? "expand" : "collapse",
      internalLinkPosition: "search results",
      internalLinkType: "search results:" + facetName,
      internalLinkZoneName: `search results:${selectedTab}:filters`,
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    })
  } else if (page === "literature") {
    analyticsData = JSON.stringify({
      eventAction: `literature :filters ${
        collapse[facet] ? "expand" : "collapse"
      }`,
      eventName: `literature :filters ${
        collapse[facet] ? "expand" : "collapse"
      }`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: collapse[facet] ? "expand" : "collapse",
      internalLinkPosition: "literature",
      internalLinkType: "literature:" + facetName,
      internalLinkZoneName: `literature :filters`,
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    })
  }

  return analyticsData
}

const getFilterOptionsAnalyticsData = ({
  obj,
  appliedFilters,
  facetLabels,
  facetName,
  isSelected,
  page,
  selectedTab,
}) => {
  const getAppliedFiltersForPlpAnalyticsData = objDisplay => {
    const dataLayerFilterArray = []
    appliedFilters.forEach(filter => {
      filter.display.forEach(filtervalue => {
        dataLayerFilterArray.push(
          `${formatFacetName(filter.facet, null, facetLabels)}:${filtervalue}`
        )
      })
    })
    const myIndex = dataLayerFilterArray.indexOf(`${facetName}:${objDisplay}`)
    if (myIndex !== -1) {
      dataLayerFilterArray.splice(myIndex, 1)
    } else {
      dataLayerFilterArray.push(`${facetName}:${objDisplay}`)
    }
    return dataLayerFilterArray.length ? dataLayerFilterArray.join("|") : ""
  }

  const plpFilterArrayData = getAppliedFiltersForPlpAnalyticsData(obj.display)

  let analyticsData = ""

  const appliedOrRemoved = isSelected(obj) ? "removed" : "applied"

  if (page === "search") {
    analyticsData = JSON.stringify({
      eventAction: `search results:${selectedTab}:filters ${
        isSelected(obj) ? "removed" : "applied"
      }`,
      eventName: `search results:${selectedTab}:filters ${
        isSelected(obj) ? "removed" : "applied"
      }`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${obj.display}`,
      internalLinkPosition: "search results",
      internalLinkType: `search results:${facetName}`,
      internalLinkZoneName: `search results:${selectedTab}:filters`,
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
      filterArray: plpFilterArrayData,
    })
  } else if (page === "technicalspecs") {
    analyticsData = JSON.stringify({
      eventAction: `technical specs:filters ${appliedOrRemoved}`,
      eventName: `technical specs:filters ${appliedOrRemoved}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${obj.display}:${appliedOrRemoved}`,
      internalLinkPosition: "technical specs",
      internalLinkType: `technical specs:${facetName}`,
      internalLinkZoneName: "technical specs:filters",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
      filterArray: plpFilterArrayData,
    })
  } else if (page === "article") {
    analyticsData = JSON.stringify({
      eventAction: `support:help and faq:filters:filter ${appliedOrRemoved}`,
      eventName: `support:help and faq:filters:filter ${appliedOrRemoved}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${obj.display}:${appliedOrRemoved}`,
      internalLinkPosition: "knowledge articles",
      internalLinkType: `support:help and faq:${facetName}`,
      internalLinkZoneName: "support:help and faq:filters",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
      filterArray: plpFilterArrayData,
    })
  } else if (page === "literature") {
    analyticsData = JSON.stringify({
      eventAction: `literature :filters ${
        isSelected(obj) ? "removed" : "applied"
      }`,
      eventName: `literature :filters ${
        isSelected(obj) ? "removed" : "applied"
      }`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${obj.display.toLowerCase()} :${
        isSelected(obj) ? "removed" : "applied"
      }`,
      internalLinkPosition: "literature",
      internalLinkType: `literature:${facetName.toLowerCase()}`,
      internalLinkZoneName: `literature:filters`,
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
      filterArray: plpFilterArrayData.toLowerCase(),
    })
  } else {
    analyticsData = JSON.stringify({
      eventAction: `plp filter ${isSelected(obj) ? "removed" : "applied"}`,
      eventName: `plp filter ${isSelected(obj) ? "removed" : "applied"}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${obj.display}`,
      internalLinkPosition: "plp",
      internalLinkType: `plp:${facetName}`,
      internalLinkZoneName: "plp:filters",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
      filterArray: `${plpFilterArrayData}`,
    })
  }

  return analyticsData
}

export const addFilterCancelDataLayerOnClick = (
  value,
  appliedFilters,
  facet,
  filter,
  selectedTab,
  page,
  facetLabels = []
) => {
  const filterArrayList = getAppliedFiltersForPlpAnalyticsData(
    appliedFilters,
    facet.facet,
    filter,
    facetLabels
  )
  let type = "plp filter "
  let internalLinkPosition = "plp"
  let internalValue = "plp"
  let internalLinkName = filter
  if (page === "article") {
    type = "support:help and faq:filters:"
    internalLinkPosition = "knowledge articles"
    internalValue = "support:help and faq"
    internalLinkName = `${filter}:removed`
  } else if (page === "search") {
    type = `search results:${selectedTab}:filters `
    internalLinkPosition = "search results"
    internalValue = `search results:${selectedTab}`
  } else if (page === "technicalspecs") {
    type = "technical specs:filter "
    internalLinkPosition = "technical specs"
    internalValue = "technical specs"
    internalLinkName = `${filter}:removed`
  }
  const eventValue = `${type}${value}`.toLowerCase()
  const eventInfo = {
    eventAction: eventValue,
    eventName: eventValue,
    eventType: "navigation",
    filterArray: filterArrayList,
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: internalLinkName.toLowerCase(),
    internalLinkPosition: internalLinkPosition.toLowerCase(),
    internalLinkType: `${internalValue}:${formatFacetName(
      facet["facet"],
      null,
      facetLabels
    )}`.toLowerCase(),
    internalLinkZoneName: `${internalValue}:filters`.toLowerCase(),
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

const getAppliedFiltersForPlpAnalyticsData = (
  appliedFilters,
  facet,
  obj,
  facetLabels = []
) => {
  const dataLayerFilterArray = []
  appliedFilters.forEach(filter => {
    filter.display.forEach(filtervalue => {
      dataLayerFilterArray.push(
        `${formatFacetName(
          filter.facet,
          null,
          facetLabels
        )}:${filtervalue}`.toLowerCase()
      )
    })
  })
  const myIndex = dataLayerFilterArray.indexOf(
    `${formatFacetName(facet, null, facetLabels)}:${obj}`.toLowerCase()
  )
  if (myIndex !== -1) {
    dataLayerFilterArray.splice(myIndex, 1)
  } else {
    dataLayerFilterArray.push(
      `${formatFacetName(facet, null, facetLabels)}:${obj}`.toLowerCase()
    )
  }
  return dataLayerFilterArray.length ? dataLayerFilterArray.join("|") : ""
}

const getFilterClearAllAnalyticsData = (sectionTab, page) => {
  let analyticsData = {
    eventAction: "plp clear all filter",
    eventName: "plp clear all filter",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "clear all",
    internalLinkPosition: "plp",
    internalLinkType: "plp:clear all",
    internalLinkZoneName: "plp:filters",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  if (page === "article") {
    analyticsData = {
      eventAction: "support:help and faq:filters​:clear all",
      eventName: "support:help and faq:filters​:clear all",
      eventType: "informative",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: "clear all",
      internalLinkPosition: "knowledge articles",
      internalLinkType: "support:help and faq:navigation",
      internalLinkZoneName: "support:help and faq:filters​​",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
  }
  if (page === "search") {
    analyticsData = {
      clickInternalLinks: "true",
      eventAction: `search results:${sectionTab}:clear all`,
      eventName: `search results:${sectionTab}:clear all`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: "clear all",
      internalLinkPosition: "search results",
      internalLinkType: "search results:clear all",
      internalLinkURL: "n/a",
      internalLinkZoneName: `search results:${sectionTab}:filters`,
    }
  }
  if (page === "literature") {
    analyticsData = {
      clickInternalLinks: "true",
      eventAction: `literature:filters:clear all`,
      eventName: `literature:filters:clear all`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: "clear all",
      internalLinkPosition: "literature",
      internalLinkType: "literature:clear all",
      internalLinkURL: "n/a",
      internalLinkZoneName: `literature:filters`,
    }
  }

  return JSON.stringify(analyticsData)
}

const getAnalyticsData = isUnchecked => {
  const hideDiscontinuedEventObj = {
    eventAction: "search results:technical:filters​:hide discontinued products",
    eventName: "search results:technical:filters​:hide discontinued products",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `hide discontinued products:${
      isUnchecked ? "unchecked" : "checked"
    }`,
    internalLinkPosition: "search results",
    internalLinkType: "search results:checkbox",
    internalLinkZoneName: "search results:technical:filters",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  return JSON.stringify(hideDiscontinuedEventObj)
}

const getPrintOrShareEventInfo = (printOrShare, selectedTab, page) => {
  let analyticsData = ""

  if (page === "search") {
    analyticsData = {
      eventAction: `search results:${selectedTab}:filters:${printOrShare}`,
      eventName: `search results:${selectedTab}:filters:${printOrShare}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: printOrShare,
      internalLinkPosition: "search results",
      internalLinkType: `search results:${printOrShare}`,
      internalLinkZoneName: `search results:${selectedTab}:filters`,
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
  } else if (page === "technicalspecs") {
    analyticsData = {
      eventAction: `technical specs:filters:${printOrShare}`,
      eventName: `technical specs:filters:${printOrShare}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: printOrShare,
      internalLinkPosition: "tech specs",
      internalLinkType: `technical specs:${printOrShare}`,
      internalLinkZoneName: "technical specs:filters​",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
  } else if (page === "literature") {
    analyticsData = {
      eventAction: `literature:filters:${printOrShare}`,
      eventName: `literature:filters:${printOrShare}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: printOrShare,
      internalLinkPosition: "literature",
      internalLinkType: `literature:${printOrShare}`,
      internalLinkZoneName: `literature:filters`,
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
  } else {
    analyticsData = {
      eventAction: `plp ${printOrShare}`,
      eventName: `plp ${printOrShare}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: printOrShare,
      internalLinkPosition: "support:help and faq",
      internalLinkType: `support:help and faq:print`,
      internalLinkZoneName: "support:help and faq:filters",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
  }

  return JSON.stringify(analyticsData)
}

const getFilterCloseAnalyticsData = page => {
  let analyticsData = ""
  if (page === "article") {
    analyticsData =
      '{"eventAction":"support:help and faq:filters​:close","eventName":"support:help and faq:filters​:close","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"close(x)","internalLinkPosition":"knowledge articles","internalLinkType":"support:help and faq:close","internalLinkZoneName":"support:help and faq:filters​","internalLinkURL":"n/a","clickInternalLinks":"true"}'
  } else {
    analyticsData =
      '{"eventAction":"plp filter close","eventName":"plp filter close","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"close","internalLinkPosition":"plp","internalLinkType":"plp:close","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true"}'
  }

  return analyticsData
}
const getFilterViewResultsAnalyticsData = (
  facetLabels,
  page,
  appliedFilters
) => {
  let analyticsData = ""
  if (page === "article") {
    analyticsData =
      '{"eventAction":"support:help and faq:filters​:view results","eventName":"support:help and faq:filters​:view results","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"view results","internalLinkPosition":"knowledge articles","internalLinkType":"support:help and faq:navigation","internalLinkZoneName":"support:help and faq:filters​","internalLinkURL":"n/a","clickInternalLinks":"true"}'
  } else {
    analyticsData =
      '{"eventAction":"plp filter view result","eventName":"plp filter view result","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"view result","internalLinkPosition":"plp","internalLinkType":"plp:filters","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true","filterArray":"' +
      getAppliedFiltersForAnalytics(appliedFilters, facetLabels) +
      '"}'
  }

  return analyticsData
}

const getFilterCloseImgAnalyticsData = () => {
  const analyticsData =
    '{"eventAction":"plp filter close","eventName":"plp filter close","eventType":"informative","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"close","internalLinkPosition":"close","internalLinkType":"plp:close","internalLinkZoneName":"plp:filters","internalLinkURL":"n/a","clickInternalLinks":"true"}'

  return analyticsData
}
const getSocialShareAnalyticsData = (page, selectedTab) => {
  let analyticsData = ""
  if (page === "technicalspecs") {
    analyticsData = {
      eventAction: "technical specs:filters:share",
      eventName: "technical specs:filters:share",
      internalLinkPosition: "tech specs",
      internalLinkType: "technical specs​:share",
      internalLinkZoneName: "technical specs:filters",
    }
  } else if (page === "search") {
    analyticsData = {
      eventAction: `search results:${selectedTab}:filters:share`,
      eventName: `search results:${selectedTab}:filters:share`,
      internalLinkPosition: "search results",
      internalLinkType: "search results:share",
      internalLinkZoneName: `search results:${selectedTab}:filters`,
    }
  } else {
    analyticsData = {
      eventAction: "plp share",
      eventName: "plp share",
      internalLinkPosition: "support:help and faq",
      internalLinkType: "support:help and faq:share",
      internalLinkZoneName: "support:help and faq:filters",
    }
  }

  return analyticsData
}

export {
  getShowHideFilterOptionAnalyticsData,
  getFilterOptionsAnalyticsData,
  getFilterClearAllAnalyticsData,
  getAnalyticsData,
  getPrintOrShareEventInfo,
  getFilterCloseAnalyticsData,
  getFilterCloseImgAnalyticsData,
  getFilterViewResultsAnalyticsData,
  getSocialShareAnalyticsData,
}
